'use client';

import * as Sentry from '@sentry/nextjs';
import NextError from 'next/error';
import { useEffect } from 'react';
import {useUnit} from "effector-react";
import {$configGamePageStore, ConfigGamePage} from "@/root/app/[locale]/games/[slug]/model";
import {aiTrackServerError} from "@/root/app/error";
type TProps = Readonly<{ error: Error & { digest?: string } }>;

export default function GlobalError({ error }: TProps) {
    const configGamePage = useUnit($configGamePageStore) as ConfigGamePage;
    const asyncEffect = async () => {
        console.error(error);
        await aiTrackServerError(configGamePage, error);
    };
    useEffect(() => {
        Sentry.captureException(error);
        asyncEffect();
    }, [error]);

    return (
        <html>
            <body>
                {/* This is the default Next.js error component but it doesn't allow omitting the statusCode property yet. */}
                <NextError statusCode={undefined as any} />
            </body>
        </html>
    );
}
